<template>
    <div>
        <div class="actions__container">
            <div>
                <a
                    href="#"
                    class="btn btn-primary w-40 inline-block mr-1 mb-2"
                    @click.prevent="$router.push({ name: 'tariffCreate' })"
                >
                    Создать
                </a>
            </div>
        </div>

        <ContentSearch />

        <ContentTable
            :inverse-toggle-icons="true"
            :table-actions="tableActions"
            :headers="tableHeaders"
            :table-rows="tableData"
            :meta="pagination"
            :loading="loading"
            @edit="editAction"
            @delete="deleteAction"
        />

        <PopupDeleteModal
            :is-open="modalOpen"
            :entity-name="modalTitle"
            :modal-data="modalData"
            @update:isOpen="modalOpen = $event"
            @deleteModalAccepted="onDeleteModalAccepted"
        />
    </div>
</template>

<script>
import ContentTable from '@/components/tables/ContentTable';
import PopupDeleteModal from '@/components/modals/PopupDeleteModal';
import { errorResponse } from '@/mixins/form/form-mixin';
import ContentSearch from '../../components/base/ContentSearch';

export default {
    components: { ContentSearch, PopupDeleteModal, ContentTable },
    mixins: [errorResponse],
    async beforeRouteUpdate(to, from, next) {
        await this.fetchTariffs({ ...to.query });
        next();
    },
    data() {
        return {
            loading: false,
            modalOpen: false,
            modalTitle: null,
            modalData: null,
            tableActions: [
                { name: 'edit', title: 'редактировать', icon: 'Edit2Icon', cbName: 'edit' },
                { name: 'delete', title: 'удалить', class: 'bg-red-400', icon: 'TrashIcon', cbName: 'delete' },
            ],
            tableHeaders: [
                { field: 'id', label: '#' },
                { field: 'title', label: 'Название' },
                { field: 'discount_title', label: 'Название акции' },
                { field: 'discount_description', label: 'Описание акции' },
                { field: 'percent', label: 'Скидка' },
            ],
            tableData: [],
            pagination: null,
        };
    },
    async created() {
        this.$store.commit('main/setPageHeader', 'Тарифы');
        await this.fetchTariffs(this.$route.query);
    },
    methods: {
        async fetchTariffs(params) {
            try {
                this.loading = true;
                const { data } = await this.axios.get('/tariffs', { params });
                this.tableData = data.data;
                this.pagination = data.meta;
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant fetch users: ' + ex);
            }
        },
        editAction(row) {
            this.$router.push({ name: 'tariffEdit', params: { id: row.id } });
        },
        deleteAction(row) {
            this.modalData = row;
            this.modalOpen = true;
        },
        onDeleteModalAccepted(data) {
            this.axios
                .delete('/tariffs/' + data.id)
                .then((res) => {
                    this.fetchTariffs(this.$route.query);
                    this.modalOpen = false;
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
    },
};
</script>

<style></style>
